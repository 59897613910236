<template>
  <div :class="`m-3 wrapper  wrapper--${bu}`">
    <div class="text-center p-2">
      <h2>Inkopen per dag</h2>
      <span @click="previousDay"><i class="fas fa-arrow-left"></i></span>
      <span class="text-center m-2">{{ full_date }}</span>
      <span @click="nextDay"><i class="fas fa-arrow-right"></i></span>
    </div>
    <Loading v-if="loading" />
    <div v-if="!loading">
      <div v-for="(salesman, key) in bought_per_day" :key="key" class="mb-3 ml-2 mr-2">
        <h2 class="text-center">{{ key }}</h2>
        <table :class="`w-100 table--default table__border--${bu}`">
          <thead :class="`table__head--${bu}`">
            <tr>
              <th class="table__head--text">Item</th>
              <th class="table__head--text">Voertuig</th>
              <th v-if="checkroles(['lev_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__head--text">Leverancier</th>
              <th v-if="checkroles(['lev_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__head--text">Naam Leverancier</th>
              <th class="table__head--text">Verwacht</th>
              <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__head--text table__data--right">Inkoopprijs</th>
              <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__head--text table__data--right">Vraagprijs</th>
              <th class="table__head--text table__data--right">Status</th>
              <th class="table__head--text table__data--right">Consignatie</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, key) in salesman"
              :key="key"
              :class="`table__row--color`"
            >
              <td class="table__cell--default">
                <ImageHover :itemnummer="item.nummer" :bu="bu" />
              </td>
              <td class="table__cell--default">{{ item.voertuig }}</td>
              <td v-if="checkroles(['lev_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__cell--default">{{ item.leverancier }}</td>
              <td v-if="checkroles(['lev_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__cell--default">{{ item.leveranciernaam }}</td>
              <td class="table__cell--default">{{ item.verwacht }}</td>
              <td v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__cell--default table__data--right">
                € {{ item.inkoopprijs }}
              </td>
              <td v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__cell--default table__data--right">
                € {{ item.vraagprijs }}
              </td>
              <td class="table__cell--default table__data--right">
                {{ item.status }}
              </td>
              <td class="table__cell--default table__data--right">
                {{ item.consignatie }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import request from "@/functions/request.js";
import Loading from "@/components/Loading";
import { subtractTime, addTime } from "@/functions/date.js";
import ImageHover from "@/components/ImageHover.vue";


export default {
  props: ["bu"],
  components: { Loading, ImageHover },
  data: () => ({
    bought_per_day: null,
    full_date: moment().format("YYYY/MM/DD"),
    loading: true,
  }),
  mounted() {
    this.getData(this.bu);
  },
  watch: {
    bu(newbu) {
      this.getData(newbu);
    },
  },
  methods: {
    getData(bu) {
      this.loading = true;
      const url = `bought-per-day/${this.full_date}/${bu}`;
      request(url, "GET").then(({ bought_per_day }) => {
        this.bought_per_day = bought_per_day;
        this.loading = false;
      });
    },
    previousDay() {
      this.full_date = subtractTime(1, this.full_date, "days", "YYYY/MM/DD");
      this.getData(this.bu);
    },
    nextDay() {
      this.full_date = addTime(1, this.full_date, "days", "YYYY/MM/DD");
      this.getData(this.bu);
    },

    
  },
};
</script>
